
























































































import Vue, { VueConstructor } from 'vue'
import axios from 'axios'
import { mapState } from 'vuex'
import {
  intervalToDuration,
  formatDuration,
  format,
  addSeconds,
} from 'date-fns'

import VueCountdown from '@chenfengyuan/vue-countdown'

import { IStateRoot } from '../../store/models/IStateRoot'

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT

export default (Vue as VueConstructor<Vue & IStateRoot>).extend({
  name: 'UpvoteQueue',
  components: {
    VueCountdown,
  },
  data() {
    return {
      upvotes: {},
      selectedStatus: 'pending',
      statusOptions: ['pending', 'completed', 'canceled', 'failed'],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      user: (state: any) => state.auth.user,
    }),
  },
  mounted() {
    this.getUpvotes()
  },
  methods: {
    async getUpvotes() {
      try {
        this.loading = true

        const response = await axios.get(`${API_ENDPOINT}/posts/upvotes`, {
          params: {
            status: this.selectedStatus,
          },
          headers: {
            Authorization: `Bearer ${this.user.accessToken.token}`,
          },
        })

        this.upvotes = response.data
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    cancelVote(id: number) {
      axios
        .delete(`${API_ENDPOINT}/posts/upvotes/${id}`, {
          headers: {
            Authorization: `Bearer ${this.user.accessToken.token}`,
          },
        })
        .then((res) => {
          this.getUpvotes()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    formatDate(date: string) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
    },
    formatDuration(seconds: number) {
      const end = addSeconds(new Date(), seconds)

      const duration = intervalToDuration({
        start: new Date(),
        end,
      })

      return formatDuration(duration)
        .replace(' days', 'd')
        .replace(' day', 'd')
        .replace(' hours', 'h')
        .replace(' hour', 'h')
        .replace(' minutes', 'm')
        .replace(' minute', 'm')
        .replace(' seconds', 's')
        .replace(' second', 's')
    },
    formatToDate(date: string) {
      const end = new Date(date)

      const duration = intervalToDuration({
        start: new Date(),
        end,
      })

      return formatDuration(duration)
    },
    getMillisecondsUntil(date: string) {
      const end = new Date(date)

      return end.getTime() - new Date().getTime()
    },
    formatBoolean(value: boolean) {
      return value ? 'Yes' : 'No'
    },
  },
})
